import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { Dialog } from '../../../../../dataset/Dialog';
import { IDialog } from '../interfaces/IDialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-license-modal',
  templateUrl: './license-modal.component.html',
  styleUrls: ['./license-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LicenseModalComponent extends Dialog implements IDialog {
  readonly type = input.required<string>();

  closeModal(): void {
    this.resolve(false);
  }
}
