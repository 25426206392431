import {
  ActivatedRoute,
  Event as RouterEvent,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { concatMap, filter, map } from 'rxjs/operators';

import { AppConfiguration } from '../core/services/configuration/app.configuration';
import { Component } from '@angular/core';
import { ConfirmEmailSubscriptionModalComponent } from '../shared/components/ui/modals/confirm-email-subscription-modal/confirm-email-subscription-modal.component';
import { DialogService } from '../shared/components/ui/dialog/services/dialog.service';
import { LicenseModalComponent } from '../shared/components/ui/modals/license-modal/license-modal.component';
import { SaleModalComponent } from '../shared/components/ui/modals/sale-modal/sale-modal.component';
import { StorageContextService } from '../context/storage-context/storage-context.service';
import { SubscriptionContextService } from '../context/subscription-context/subscription-context.service';
import { User } from '../dataset/User';
import { UserApiService } from '../api/user-api/user-api.service';
import { UserContextService } from '../context/user-context/user-context.service';
import dayjs from 'dayjs';

@UntilDestroy()
@Component({
  templateUrl: './dashboard.component.html',
  styleUrls: ['dashboard.component.scss'],
})
export class DashboardComponent {
  loading: boolean;
  skipSaleOffer: boolean;

  user: User | null = this.userContext.get();

  constructor(
    private storageContext: StorageContextService,
    private subscriptionContext: SubscriptionContextService,
    private appConfiguration: AppConfiguration,
    private router: Router,
    private userApiService: UserApiService,
    private userContext: UserContextService,
    private dialogService: DialogService,
    private route: ActivatedRoute
  ) {
    if (this.storageContext.getItem('license')) {
      this.userApiService
        .licenseActivate(
          this.storageContext.getItem('license'),
          this.storageContext.getItem('license_type')
        )
        .pipe(
          concatMap(() => this.userContext.retrieve()),
          map(user => {
            this.userContext.next(user);
            return user;
          })
        )
        .subscribe(user => {
          if (user?.license_id) {
            this.dialogService.open(LicenseModalComponent, {
              type: this.storageContext.getItem('license_type'),
            });
          }
        });

      this.storageContext.removeItem('license');
    }

    this.skipSaleOffer = this.route.snapshot.queryParamMap.get('bundle') === 'true';
    this.router.events
      .pipe(
        filter(
          event =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        ),
        untilDestroyed(this)
      )
      .subscribe((event: RouterEvent) => {
        switch (event.constructor) {
          case NavigationEnd:
            window.scrollTo(0, 0);
            this.loading = false;
            break;
          case NavigationStart:
            this.loading = true;
            break;
          default:
            this.loading = false;
            break;
        }
      });

    this.init();
  }

  async init(): Promise<void> {
    if (this.subscriptionContext.showSale) {
      await this.showSaleModal();
    }

    if (this.user) {
      const daysRegistered = dayjs().diff(dayjs(this.user.created_at), 'days');

      if (
        this.user.is_mailing_confirmed === null &&
        daysRegistered > 1 &&
        !this.router.url?.includes('profile/subscriptions')
      ) {
        const doConfirm = await this.dialogService.open<boolean>(
          ConfirmEmailSubscriptionModalComponent
        );

        const user = Object.assign({}, this.user, {
          is_mailing_confirmed: doConfirm,
        });

        const output = new User(user);
        output.profileStatistics = this.userContext.get()?.profileStatistics;
        this.userContext.next(output);

        this.userApiService.confirmEmail(doConfirm).pipe(untilDestroyed(this)).subscribe();
      }
    }
  }

  async showSaleModal(): Promise<void> {
    if (
      this.route.snapshot.data.disableSalePopup ||
      (this.route.snapshot.queryParams?.productId && this.route.snapshot.queryParams?.apiKey)
    ) {
      return;
    }
    const { theme, enable, unauthorized } = this.appConfiguration.sale;
    if (enable) {
      if (this.storageContext.getItem(theme) !== 'disabled') {
        await this.dialogService.open(SaleModalComponent);
        this.storageContext.setItem(theme, 'disabled');
      } else if (
        !this.storageContext.getItem('access_token') &&
        !this.storageContext.getItem(unauthorized)
      ) {
        await this.dialogService.open(SaleModalComponent);
        this.storageContext.setItem(unauthorized, 'disabled');
      }
    }
  }
}
